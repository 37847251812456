import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, IconButton, Box, Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IThemeConfig } from '@ccs-dip/common/types/app-config';
import { useTranslation } from 'react-i18next';
import { sanitizeText } from 'utils/Utils';
import useThemeNotification from './useThemeNotification';
import { useSelector } from 'react-redux';
import { State } from 'store/store';

interface SettingsDialogProps {
  open: boolean;
  themeToEdit: IThemeConfig | null;
  onSave: (theme: IThemeConfig) => void;
  onClose: () => void;
}

export const SettingsDialog: React.FC<SettingsDialogProps> = ({ open, themeToEdit, onSave, onClose }) => {
  const { t } = useTranslation();
  const [newThemeName, setNewThemeName] = useState(themeToEdit?.title || '');
  const [isSelected, setIsSelected] = useState(themeToEdit?.isSelected || false);
  const [isDefault, setIsDefault] = useState(themeToEdit?.isDefault || false);
  const defaultTheme = useSelector((state: State) => state.themeConfig.defaultTheme);

  // New fields for typography color, background, and drawer color
  const [primaryColor, setPrimaryColor] = useState(themeToEdit?.theme.palette.primary.main || defaultTheme?.theme?.palette?.primary?.main);
  const [secondaryColor, setSecondaryColor] = useState(themeToEdit?.theme.palette.secondary.main || defaultTheme?.theme?.palette?.secondary?.main);
  const [typographyColor, setTypographyColor] = useState(themeToEdit?.theme.typography?.color || defaultTheme?.theme?.typography?.color);
  const [pageBackgroundColor, setPageBackgroundColor] = useState(themeToEdit?.theme.palette.background?.default || defaultTheme?.theme?.palette?.background?.default);
  const [drawerBackgroundColor, setDrawerBackgroundColor] = useState(themeToEdit?.theme.components?.MuiDrawer?.styleOverrides?.paper?.backgroundColor || '#f2f2f2');
  
  // New state for text color pickers
  const [primaryTextColor, setPrimaryTextColor] = useState(themeToEdit?.theme.palette.text?.primary || defaultTheme?.theme.palette.text?.primary);
  const [secondaryTextColor, setSecondaryTextColor] = useState(themeToEdit?.theme.palette.text?.secondary || defaultTheme?.theme.palette.text?.secondary);

  const [iconPreview, setIconPreview] = useState<string | null>(themeToEdit?.logo || null);
  const [iconMiniPreview, setIconMiniPreview] = useState<string | null>(themeToEdit?.logoSmall || null);

  // New state to control the visibility of typography, background, and drawer fields
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const { themeNotification } = useThemeNotification();

  useEffect(() => {
    if (themeToEdit) {
      setNewThemeName(themeToEdit?.title);
      setIsSelected(themeToEdit.isSelected);
      setIsDefault(themeToEdit.isDefault);
      setPrimaryColor(themeToEdit.theme.palette.primary.main);
      setSecondaryColor(themeToEdit.theme.palette.secondary.main);
      setTypographyColor(themeToEdit.theme.typography?.color || '#000000');
      setPageBackgroundColor(themeToEdit.theme.palette.background?.default || '#ffffff');
      setDrawerBackgroundColor(themeToEdit.theme.components?.MuiDrawer?.styleOverrides?.paper?.backgroundColor || '#ffffff');
      setPrimaryTextColor(themeToEdit.theme.palette.text?.primary || '#000000');
      setSecondaryTextColor(themeToEdit.theme.palette.text?.secondary || '#000000');
      setIconPreview(themeToEdit.logo);
      setIconMiniPreview(themeToEdit?.logoSmall);
    }
  }, [themeToEdit]);

  const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>, isMini: boolean = false) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (isMini) {
          setIconMiniPreview(reader.result as string);
        } else {
          setIconPreview(reader.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const clearDialogFields = () => {
    setNewThemeName('');
    setPrimaryColor(defaultTheme?.theme.palette.primary.main);
    setSecondaryColor(defaultTheme?.theme.palette.secondary.main);
    setTypographyColor(defaultTheme?.theme.typography?.color);
    setPageBackgroundColor(defaultTheme?.theme.palette.background?.default);
    setDrawerBackgroundColor(defaultTheme?.theme?.components?.MuiDrawer?.styleOverrides?.paper?.backgroundColor || '#f2f2f2');
    setPrimaryTextColor(defaultTheme.theme.palette.text?.primary);
    setSecondaryTextColor(defaultTheme.theme.palette.text?.secondary);
    setIconPreview(null);
    setIconMiniPreview(null);
  };

  const handleSave = () => {
    if (newThemeName && primaryColor && secondaryColor && typographyColor && pageBackgroundColor && drawerBackgroundColor && iconPreview && iconMiniPreview) {
      const newTheme: IThemeConfig = {
        key: themeToEdit ? themeToEdit.key : sanitizeText(newThemeName.toLowerCase()),
        title: newThemeName,
        isDefault: isDefault,
        logo: iconPreview,
        isSelected: isSelected,
        logoSmall: iconMiniPreview,
        theme: {
          typography: { fontFamily: 'Open Sans', color: typographyColor },
          shape: { borderRadius: 8 },
          palette: {
            primary: { main: primaryColor, contrastText: '#fff' },
            secondary: { main: secondaryColor, contrastText: '#000' },
            background: { default: pageBackgroundColor, paper: drawerBackgroundColor },
            text: { primary:primaryTextColor, secondary:secondaryTextColor} // Use the new state values for text colors
          },
          components: {
            MuiListItemButton: {
              styleOverrides: {
                root: {
                  '&:hover': {
                    backgroundColor: '#fff',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#fff',
                    '&:hover': {
                      backgroundColor: '#fff',
                    }
                  }
                }
              }
            },
            MuiDrawer: {
              styleOverrides: {
                paper: {
                  backgroundColor: drawerBackgroundColor
                }
              }
            }
          }
        }
      };

      onSave(newTheme);
      clearDialogFields();
    } else {
      themeNotification.fieldsareRequired();
    }
  };

  return (
    <>
      {open ? (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {themeToEdit ? t('edit') : t('new')}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
              <TextField
                label={t('name')}
                value={newThemeName}
                onChange={(e) => setNewThemeName(e.target.value)}
                fullWidth
                required
              />

              <Button variant='contained' component='label'>
                {t('uploadIcon')}
                <input type='file' hidden onChange={(e) => handleIconChange(e, false)} accept='image/*' />
              </Button>
              {iconPreview && (
                <img src={iconPreview} alt='Full Preview' style={{ width: '100px', marginTop: '10px' }} />
              )}

              <Button variant='contained' component='label'>
                {t('uploadMini')}
                <input type='file' hidden onChange={(e) => handleIconChange(e, true)} accept='image/*' />
              </Button>
              {iconMiniPreview && (
                <img src={iconMiniPreview} alt='Mini Preview' style={{ width: '50px', marginTop: '10px' }} />
              )}

              <TextField
                label={t('primaryColor')}
                type='color'
                value={primaryColor}
                onChange={(e) => {setPrimaryColor(e.target.value); setTypographyColor(e.target.value)}}
                fullWidth
                required
              />

              <TextField
                label={t('secondaryColor')}
                type='color'
                value={secondaryColor}
                onChange={(e) => setSecondaryColor(e.target.value)}
                fullWidth
                required
              />

              {/* Checkbox to toggle advanced options */}
              <FormControlLabel
                control={<Checkbox checked={showAdvancedOptions} onChange={() => setShowAdvancedOptions(!showAdvancedOptions)} />}
                label={t('showAdvancedOptions')}
              />

              {/* Conditionally render advanced options */}
              {showAdvancedOptions && (
                <>
                  <TextField
                    label={t('typographyColor')}
                    type='color'
                    value={typographyColor}
                    onChange={(e) => setTypographyColor(e.target.value)}
                    fullWidth
                  />

                  <TextField
                    label={t('pageBackgroundColor')}
                    type='color'
                    value={pageBackgroundColor}
                    onChange={(e) => setPageBackgroundColor(e.target.value)}
                    fullWidth
                  />

                  <TextField
                    label={t('drawerBackgroundColor')}
                    type='color'
                    value={drawerBackgroundColor}
                    onChange={(e) => setDrawerBackgroundColor(e.target.value)}
                    fullWidth
                  />

                  {/* New TextField for primary text color */}
                  <TextField
                    label={t('primaryTextColor')}
                    type='color'
                    value={primaryTextColor}
                    onChange={(e) => setPrimaryTextColor(e.target.value)}
                    fullWidth
                  />

                  {/* New TextField for secondary text color */}
                  <TextField
                    label={t('secondaryTextColor')}
                    type='color'
                    value={secondaryTextColor}
                    onChange={(e) => setSecondaryTextColor(e.target.value)}
                    fullWidth
                  />
                </>
              )}

              <Button onClick={handleSave} variant='contained' color='primary'>
                {themeToEdit ? t('updateTheme') : t('saveTheme')}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        ''
      )}
    </>
  );
};
