import { IForm } from '@ccs-dip/common/types/formio-types';
import FormioService from 'entities/Formio/FormioService';
import { formioEventHandlerType } from '../attachEventHandlerToFormioInstance';
import { Utils, Form } from 'formiojs';
import { checkIsWizard, getFieldValue, getNewInstanceByDetachingSubWizardsUpdatedEvent } from './helperFunctions';

//===============================================
// private variables
//===============================================
const formio_service = new FormioService();

// TODO: Replace this logic, if there is a better way
const createWizardInstance = (instance: any) => {
  const wizardInstance = new Form().create('wizard');
  // copying over the previous instance object's values.
  Object.keys(instance).forEach((key) => {
    wizardInstance[key] = instance[key];
  });
  return wizardInstance;
};

const createWebFormInstance = (instance: any) => {
  const formInstance = new Form().create('form');
  formInstance.root = instance.root;
  formInstance.parent = instance;
  return formInstance;
};

const replaceFormByForm = (instance: any, form: IForm) => {
  if (form.display === 'wizard') {
    // Creating a wizard instance, so that it will render the wizard properly
    instance.subForm = createWizardInstance(instance.subForm);
  }
  //replacing the form
  instance.subForm.form = form;
  // updating the value, to include form key under metadata
  // which can be used by processing the submission
  const value = instance.getValue();
  value.metadata = { key: form.key };
  instance.setValue(value);
};

const replaceFormByKey = (instance: any, key: string) => {
  if (key === '') {
    // for placing empty form
    replaceFormByForm(instance, {} as IForm);
    return;
  }

  formio_service
    .get(key)
    .then((form) => {
      replaceFormByForm(instance, form);
    })
    .catch((error) => console.log(error));
};

//===============================================
// public variables
//===============================================

// emiting an event onChange of it's value
export const replaceFormChangeEventHandler: formioEventHandlerType = (
  data: any,
  instance: any,
  emitEventName: string
) => {
  const changed = data?.changed;
  if (changed) {
    const value = instance.getValue();
    // TODO: optimize event emission
    // because the change event gets triggered on any change in form
    if (changed.instance.key === instance.key) {
      instance.root.emit(emitEventName, value);
    }
  }
};

// for emitting the event for form replacement,
// because when the value gets updated using setSubmission it change event considers it as
// submission object change instead of a specific field change
// To handle that scenario this eventHandler is used
export const replaceFormBeforeSetSubmissionEventHandler: formioEventHandlerType = (
  data: any,
  instance: any,
  emitEventName: string
) => {
  const submission = data;
  if (submission) {
    const value: any = Utils.getValue(submission, instance.path);
    instance.root.emit(emitEventName, value);
  }
};

// emiting an event if subForm instance is null, otherwise replace the form by the value
export const replaceFormEventHandler: formioEventHandlerType = (data: any, instance: any, emitEventName: string) => {
  const value = data;
  if (value && typeof value === 'string') {
    // check if nested form instance is available
    if (checkIsWizard(instance)) {
      instance = getNewInstanceByDetachingSubWizardsUpdatedEvent(instance);
      const instanceValue = JSON.parse(JSON.stringify(instance.getValue()));
      if (instance.subForm === null) {
        instance.subForm = createWebFormInstance(instance);
      }
      replaceFormByValue(instance, value, instanceValue);
    } else {
      // To trigger the custom action
      instance.root.emit(emitEventName, value);
    }
  }
};

// replaces the form based on the value
export const replaceFormByValue = (instance: any, value: string, instanceValue: any) => {
  // getting form's key based on the value of a component
  formio_service
    .getKey(getFieldValue(instance, value))
    .then((key) => {
      // check before replacing the form
      if (key && instance.subForm?.form && instance.subForm.form.key !== key) {
        replaceFormByKey(instance, key);
        instance.setValue(instanceValue);
      } else if (key === undefined && instance && instance.subForm && instance.subForm.form) {
        // if key is undefined we are removing the nestedform
        replaceFormByForm(instance, {} as IForm);
      }
    })
    .catch((error) => console.log(error));
};

export const replaceFormLanguageChangedEventHandler: formioEventHandlerType = (
  _data: any,
  instance: any,
  emitEventName: string
) => {
  instance.root.emit(emitEventName, instance.getValue());
};
