import { formioEventHandlerType } from '../attachEventHandlerToFormioInstance';
import { getPanelComponent, getHtmlContentComponent } from '../generateComponentJson';
import { IComponent, IOptionValue } from '@ccs-dip/common/types/formio-types';
import { Stack } from '@ccs-dip/common/Stack';
import {
  checkIsForm,
  checkIsWizard,
  getNewInstanceByDetachingSubWizardsUpdatedEvent,
  redrawAndRestore
} from './helperFunctions';

//===============================================
// private variables
//===============================================

const isPanelEmpty = (component?: IComponent) => {
  return component ? component.type === 'panel' && (component.components?.length ?? 0) === 0 : false;
};

// used for select or radio components
const getLabelByValue = (valuesSchema: IOptionValue[], value: string | number) => {
  for (let index = 0; index < valuesSchema.length; index++) {
    const valueSchema = valuesSchema[index];
    if (valueSchema && valueSchema.value.toString() === value.toString()) {
      return valueSchema.label;
    }
  }
  return value;
};

// wrapper function for getting the value
const getValueAsLabel = (instance: any) => {
  const type = instance.component.type;
  const value = instance.getValue();
  if (type === 'select' || type === 'radio') {
    return type === 'select'
      ? instance.t(getLabelByValue(instance.component.data.values, value))
      : instance.t(getLabelByValue(instance.component.values, value));
  }
  return value;
};

// Handler for Wizard
const renderSummaryForWizard = (instance: any) => {
  // Checking if the current page is Summary
  if (instance.root.page === instance.page) {
    instance = getNewInstanceByDetachingSubWizardsUpdatedEvent(instance); // getting the latest instance
    if (instance.components) {
      // destorying the summary if it has been already rendered, to start fresh
      instance.destroyComponents();
    }

    // generating summary for all the previously filled details
    const pages: any[] = instance.root.pages.slice(0, instance.page); // To get all the previous pages/screens
    pages.forEach((page) => {
      const summaryContentComponent = getSummaryForWizard(page);
      if (!isPanelEmpty(summaryContentComponent)) {
        instance.addComponent(summaryContentComponent);
      }
    });

    redrawAndRestore(instance);
  }
};

const getSummaryForWizard = (instance: any) => {
  const enabledInstances: any[] = [];
  const stack = new Stack<IComponent>();

  // operations on stack
  const pushToStack = (title: string, key: string) => {
    const component = getPanelComponent(title, key, 'info', []);
    stack.push(component);
  };

  // operations for panels
  const getLengthOfLastPanel = () => {
    return stack.peek()?.components?.length ?? 0;
  };

  const pushToLastPanel = (component?: IComponent) => {
    if (component && !isPanelEmpty(component)) {
      stack.peek()?.components?.push(component);
    }
  };

  const createHtmlContentComponent = () => {
    if (enabledInstances.length === 0) return;
    const html = enabledInstances
      .map((enabledInstance) => `<strong>${enabledInstance.name}:</strong> ${getValueAsLabel(enabledInstance)}<br>`)
      .join('');
    pushToLastPanel(getHtmlContentComponent(`<p>${html}</p>`, `html_content-${getLengthOfLastPanel()}`));
    enabledInstances.length = 0;
  };

  // For recursively searching through the deeply nested instance
  const searchForEnabled = (currentInstance: any) => {
    if (currentInstance.components) {
      currentInstance.components.forEach((nestedComponentInstance: any) => searchForEnabled(nestedComponentInstance));
    } else if (currentInstance.subForm) {
      // nested form component
      createHtmlContentComponent();
      pushToStack(currentInstance.subForm.form.title, `nested-${currentInstance.key}`);

      searchForEnabled(currentInstance.subForm);

      createHtmlContentComponent();
      pushToLastPanel(stack.pop());
    } else {
      if (currentInstance.component.showInSummary === true) {
        enabledInstances.push(currentInstance);
      }
    }
  };

  pushToStack(instance.component.title, `summary-${instance.key}`);
  searchForEnabled(instance);
  createHtmlContentComponent();

  return stack.pop();
};

//===============================================
// public variables
//===============================================

export const summaryRenderEventHandler: formioEventHandlerType = (
  _data: any,
  instance: any,
  _emitEventName: string
) => {
  if (checkIsWizard(instance)) {
    renderSummaryForWizard(instance);
  } else if (checkIsForm(instance)) {
    // TODO: handle displaying of summary in case of form
  }
};
