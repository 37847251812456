import { Box, Typography } from '@mui/material';
import imgSrc from '../../atoms/Icons/underUC.png';

import './Underconstruction.css';

const Underconstruction = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <div className='svg-container'>
        <img alt='sample' src={imgSrc}></img>
        <Typography component={'span'} sx={{ fontSize: '20px', color: (theme:any)=>theme.palette.background.paper, textTransform: 'uppercase', mt: 4 }}>
          Page Under Construction
        </Typography>
      </div>
    </Box>
  );
};

export default Underconstruction;
