export const checkIsWizard = (instance: any) => {
  if (instance === undefined || instance === null) {
    return false;
  }
  return instance.root.form.display === 'wizard';
};

export const checkIsForm = (instance: any) => {
  if (instance === undefined || instance === null) {
    return false;
  }
  return instance.root.form.display === 'form';
};

export const getFieldValue = (instance: any, value: string) => {
  const isWizard = checkIsWizard(instance);
  return isWizard ? `${instance.root.pages[instance.page].key}-${value}` : `${instance.root.form.name}-${value}`;
};

export const getNewInstanceByDetachingSubWizardsUpdatedEvent = (instance: any) => {
  const newInstance = instance.root.getComponent(instance.path);
  newInstance.off('subWizardsUpdated');
  return newInstance;
};

// To trigger redraw and restoreComponentsContext functions of a component
export const redrawAndRestore = (instance: any) => {
  instance.triggerRedraw();
  instance.restoreComponentsContext();
};
