import { IFormioSubmissionResponse, ISubmission } from '@ccs-dip/common/types/formio-types';
import Server from 'entities/RCIPServerProxy';

//==============================================================
// FormioSubmissionService
//==============================================================

export class FormioSubmissionService {
  send(id: string, formName: string, operation: string, submission: ISubmission): Promise<IFormioSubmissionResponse> {
    const data = {
      id,
      form: {
        name: formName
      },
      component: {
        tags: [],
        properties: {
          operation
        }
      },
      submission
    };

    return Server.post('formio-submission', data).then((response: IFormioSubmissionResponse) => {
      return response;
    });
  }
}
