
import { Button, styled, Theme } from "@mui/material";
import { Link } from 'react-router-dom';
import { FormButtonProps } from "../Formrenderer/IFormDataGrid";

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width:'100%'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    px: 3,
    width:'100%'
  },
  headerTitle: {
    pl: 1,
  },
  contentBox: {
    p: 3,
  },
  card: {
    maxWidth: '100%',
    height: '100%',
    background:'linear-gradient(.301turn,#ebfcff 6.11%,#f5e9ff 187.16%)'
  },
  link: {
    position: 'relative',
    zIndex: 1000,
    textDecoration: 'none',
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    p: 2,
  },
  title: {
    textTransform: 'capitalize',
    color: (theme:Theme) => theme.palette.text.primary,
  },
  addButton: {
    width: '100%',  // Make the button take full width of its container
    height: '100%',  // Adjust the height as needed
    textAlign: 'center',
    padding: '16px',
  }
};

export const OverviewLink = styled(Link)({
  position: 'relative',
  zIndex: 1000,
  textDecoration: 'none',
  ':hover':{
    textDecoration: 'none'
  }
});

export const toolBar = { display: 'flex', justifyContent: 'flex-end', gap: '20px', py: 2 };

export const toolbarWrap = { width: '400px', display: 'inline-flex', justifyContent: 'flex-end' };

export const formButtonWrap = {
  bgcolor: (theme: Theme) => theme.palette.secondary.main,
  mr: 2,
  fontSize: '12px',
  cursor: 'pointer'
};

export const GridButton: React.FC<FormButtonProps> = ({ onClick, icon, label }) => (
  // eslint-disable-next-line react/jsx-no-undef
  <Button variant='contained' sx={formButtonWrap} onClick={onClick}>
    {icon}
    {label}
  </Button>
);