import * as React from 'react';
import Box from '@mui/material/Box';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { FormControl, IconButton, InputAdornment } from '@mui/material';
import { OutlinedFormInput } from '../../atoms/OutlinedInput/OutlinedInput';
import { keyGenerator } from '../../../utils/Utils';
import { useTranslation } from 'react-i18next';
import { InputLable } from '../InputLabel/InputLabel';

const formControlWrapper = { 'label+.MuiOutlinedInput-root': { mt: 0 } };

const random = keyGenerator();

export function InputPassword(props: any) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const { t } = useTranslation<string>();
  return (
    <Box>
      <div>
        <FormControl sx={formControlWrapper} variant='standard' fullWidth>
          <InputLable htmlFor={`${random}InputPassword`}>{t('password')}</InputLable>
          <OutlinedFormInput
            id={`${random}InputPassword`}
            type={showPassword ? 'text' : 'password'}
            name={props.name}
            value={props.value}
            sx={{background:'transparent'}}
            onChange={props.onChange}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                  {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            }
            size='small'
          />
        </FormControl>
      </div>
    </Box>
  );
}
