import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Button, Grid, Typography } from '@mui/material';
import TabPanel from 'component/organisms/TabPanel/TabPanel';
import { useParams } from 'react-router-dom';
import { PartyService } from 'entities/Party/PartyService';
import PolicyService from 'entities/Policy/PolicyService';
import ClaimService from 'entities/Claim/ClaimService';
import BillingService from 'entities/Billing/BillingService';
import PolicyListView from 'component/organisms/PolicyListView/PolicyListView';
import ArchiveService from 'entities/Archive/ArchiveService';
import ClaimsListView from 'component/organisms/ClaimsListView/ClaimsListView';
import { Link } from 'react-router-dom';
import PartyBriefView from './PartyBriefView';
import { useDispatch } from 'react-redux';
import { setBriefView, setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';
import { Action } from '../Action/Action';
import Loader from 'component/organisms/Loader/Loader';
import OfferListView from 'component/organisms/OfferListView/OfferListView';
import BillingListView from 'component/organisms/BillingListView/BillingListView';
import FileListView from 'component/organisms/FileListView/FileListView';
import { styles } from './PartyDetailsStyles';

const PartyDetails = () => {
  const { t } = useTranslation<string>();
  const { partyNumber, module } = useParams();
  const [tabmodule, setTabmodule] = React.useState<any>(module);
  const [policyData, setPolicyData] = React.useState<any>([]);
  const [offerData, setOfferData] = React.useState<any>([]);
  const [archivesData, setArchivesData] = React.useState<any>([]);
  const [claimsData, setClaimsData] = React.useState<any>([]);
  const [billingData, setBillingsData] = React.useState<any>([]);
  const [partyDetails, setPartyDetails] = useState<any>({});
  const dispatch = useDispatch();
  const [isloading, setisloading] = useState(true);

  const itemOptions = [
    t('file'),
    t('insurances'),
    t('claims'),
    t('offers'),
    t('atlook'),
    t('action')];

  async function fetchPartyDetails(search: string) {
    try {
      const service = new PartyService();
      const response: any = await service.get(search);
      return response;
    } catch (error: any) {
      console.log(error);
    }
  }

  async function fetchPolices(search: string) {
    try {
      const service = new PolicyService();
      const regularPolicies = await service.search('PartyNumber', search);
      const waitingPolicies = await service.search('PartyNumber', search + '&PolicyVersion=W');
      const allPolicies = [...regularPolicies, ...waitingPolicies];
      const policiesWithId = allPolicies.map((policy: any, index: number) => ({ ...policy, id: index + 1 }));
      setPolicyData(policiesWithId);
    } catch (error: any) {
      console.log(error);
    }
  }

  async function fetchArchives(search: string) {
    try {
      const service = new ArchiveService();
      const archives = await service.search('PartyNumber', search);
      const archivesWithId = archives.map((archives: any, index: number) => ({ ...archives, id: index + 1 }));
      setArchivesData(archivesWithId);
    } catch (error: any) {
      console.log(error);
    }
  }

  async function fetchClaims(search: string) {
    try {
      const service = new ClaimService();
      const claims = await service.search('PartyNumber', search);
      const claimsWithId = claims.map((claims: any, index: number) => ({ ...claims, id: index + 1 }));
      setClaimsData(claimsWithId);
    } catch (error: any) {
      console.log(error);
    }
  }

  async function fetchBilling(search: string) {
    try {
      const service = new BillingService();
      const billings = await service.search('PartyNumber', search);
      const billingsWithId = billings.map((billings: any, index: number) => ({ ...billings, id: index + 1 }));
      setBillingsData(billingsWithId);
    } catch (error: any) {
      console.log(error);
    }
  }

  async function fetchOffers(search: string) {
    try {
      const service = new PolicyService();
      const offers = await service.search('PartyNumber', search + '&PolicyVersion=Q');
      const offersWithId = offers.map((policy: any, index: number) => ({ ...policy, id: index + 1 }));
      setOfferData(offersWithId);
    } catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    const fetchCall = async () => {
      const response = await fetchPartyDetails(partyNumber as string);
      setPartyDetails({ ...response });
      await fetchPolices(partyNumber as string);
      await fetchArchives(partyNumber as string);
      await fetchClaims(partyNumber as string);
      await fetchOffers(partyNumber as string);
      await fetchBilling(partyNumber as string);
      setisloading(false);
    };
    fetchCall();
  }, [partyNumber]);

  useEffect(() => {
    setTabmodule(module as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module]);

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isloading} onClick={() => {}}>
        <Loader></Loader>
      </Backdrop>
      <Box id='PartyDetails' sx={{ width: '100%', p: 3 }}>
        <Action />
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={12} md={12}>
            <Box sx={{ display: 'flex',alignItems:'center'}}>
              <Typography
                sx={{
                  color: (theme: any) => theme.palette.primary.main,
                  fontWeight: 'bold',
                  height: 33,
                  fontSize: 20,
                  px: 3,
                  minWidth: '250px',
                  width:'300px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                {partyDetails?.PreferredName} {partyDetails?.Surname}{' '}
              </Typography>
              <Typography
                id='HeaderPartyNumber'
                variant='subtitle1'
                sx={{
                  fontWeight: 800,
                  fontSize: 14,
                  color: (theme) => theme.palette.secondary.main,
                  mt: 1,
                  px: 3,
                  py: 2
                }}
                gutterBottom>
                #{partyDetails?.PartyNumber || 'N/A'}
              </Typography>
              <Button
                id='DetailsBtn'
                component={Link}
                to={``}
                variant='text'
                color='secondary'
                sx={{
                  textDecoration: 'underline',
                  textTransform: 'none',
                  cursor: 'pointer',
                  marginLeft: '5px',
                  width: '100px'
                }}
                onClick={() => {
                  dispatch(setBriefView(<PartyBriefView partyNumber={String(partyDetails?.PartyNumber)} />));
                  dispatch(setOpenBriefView(true));
                }}>
                {t('details')}
              </Button>
              <Button
                id='addPolicyBtn'
                component={Link}
                to={`/formrenderer/insuroverview/${partyDetails?.PartyNumber}`}
                variant='text'
                color='secondary'
                sx={{
                  textDecoration: 'underline',
                  textTransform: 'none',
                  cursor: 'pointer',
                  marginLeft: '5px',
                  width: '150px'
                }}>
                {'Add Policy'}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TabPanel itemOptions={itemOptions} partyNumber={partyDetails?.PartyNumber} taboption={Number(tabmodule)}>
            {Number(module) === 0 &&
              (archivesData.length ? (
                <FileListView rows={archivesData} />
              ) : (
                <Box sx={styles.tabText}>{t('noRecords')}</Box>
              ))}
            {Number(module) === 1 &&
              (policyData.length ? (
                <PolicyListView rows={policyData} />
              ) : (
                <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}>{t('noRecords')} </Box>
              ))}
            {Number(module) === 2 &&
              (claimsData.length ? (
                <ClaimsListView rows={claimsData} />
              ) : (
                <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}>{t('noRecords')} </Box>
              ))}
            {Number(module) === 3 &&
              (offerData.length ? (
                <OfferListView rows={offerData} />
              ) : (
                <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}>{t('noRecords')} </Box>
              ))}

            {Number(module) === 4 &&
              (billingData.length ? (
                <BillingListView rows={billingData} />
              ) : (
                <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}>{t('noRecords')} </Box>
              ))}
            {Number(module) === 5 && <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}>{t('noRecords')} </Box>}
          </TabPanel>
        </Grid>
      </Box>
    </>
  );
};

export default PartyDetails;
