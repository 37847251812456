import { Formio, Utils } from 'formiojs';
import { IFormioSubmissionRequest, IFormioSubmissionResponse } from '@ccs-dip/common/types/formio-types';
import { tryInitializeFieldSelectElement } from './tryInitializeFieldSelectElement';
import config from 'config/config';
import { attachEventHandler } from './attachEventHandlerToFormioInstance';
import {
  replaceFormChangeEventHandler,
  replaceFormBeforeSetSubmissionEventHandler,
  replaceFormEventHandler,
  replaceFormByValue,
  replaceFormLanguageChangedEventHandler
} from './CustomPropertySupport/replaceFormFuctions';
import {
  emitAddDynamicComponent,
  addOrRemoveDynamicComponent,
  dynamicComponentContainerBeforeSetSubmissionEventHandler,
  dynamicComponentContainerEventHandler,
  emitRemoveDynamicComponent,
  dynamicComponentContainerAddDynamicComponentEventHandler,
  dynamicComponentContainerLanguageChangedEventHandler
} from './CustomPropertySupport/dynamicComponentContainerFunctions';
import { summaryRenderEventHandler } from './CustomPropertySupport/summaryComponentFunctions';
import { loaderService } from 'entities/Loader/LoaderService';

//==============================================================
// extend global interfaces
//==============================================================

declare global {
  interface Window {
    formio_support: FormioRuntimeSupport;
  }
}

//==============================================================
// private variables
//==============================================================

const send_to_server = (data: IFormioSubmissionRequest): Promise<IFormioSubmissionResponse> => {
  const url = new URL('/api/formio-submission', config.server_url).href;

  return Formio.makeStaticRequest(url, 'POST', data).catch(() => {
    // swallow error, it will be displayed by the ErrorFetchPlugin
  });
};

//==============================================================
// class FormioRuntimeSupport
//==============================================================

export class FormioRuntimeSupport {
  send(form: any, submission: any, component: any) {
    if (!form.root.checkValidity(null, true, null, true)) {
      form.root.emit('error');
      return;
    }
    const key = component.key ?? '';
    const tags = component.tags ?? [];
    const properties = component.properties ?? {};
    const data = {
      id: form.id,
      form: {
        name: form._form.name
      },
      component: {
        key,
        tags,
        properties
      },
      submission
    };
    const serviceOperation = async () => {
      try {
        await loaderService.loader(
          send_to_server(data).then((response: IFormioSubmissionResponse) => {
            const messages = response?.messages ?? [];
            const response_submission = response?.submission;
            messages.forEach((message) => {
              const component = Utils.getComponent(form.components, message.ref, false);
              component.setCustomValidity(
                {
                  level: 'error',
                  message: message.value
                },
                true,
                true
              );
              console.log('DEBUG set error', component);
            });
            if (response_submission !== undefined) {
              form.root.setSubmission(Utils._.merge(submission, response_submission));
              form.root.emit('Success');
            }
          })
        );
        console.log('Operation completed');
      } catch (error) {
        console.error('Error during async operation', error);
      }
    };
    serviceOperation();
  }

  apiFieldCustomDefaultValue() {
    tryInitializeFieldSelectElement();
  }

  // To capture value change
  replaceFormFieldCustomDefaultValue(instance: any, emitEventName: string) {
    attachEventHandler(instance, 'change', emitEventName, replaceFormChangeEventHandler);
    attachEventHandler(instance, 'beforeSetSubmission', emitEventName, replaceFormBeforeSetSubmissionEventHandler);
    attachEventHandler(instance, 'languageChanged', emitEventName, replaceFormLanguageChangedEventHandler);
  }

  // To change form from formio logic
  replaceFormNestedFormCustomAction(instance: any, value: string) {
    const instanceValue = JSON.parse(JSON.stringify(instance.getValue()));
    replaceFormByValue(instance, value, instanceValue);
  }

  // To change form from formio event or trigger logic to handle form replacement
  replaceFormNestedFormCustomDefaultValue(instance: any, eventName: string) {
    attachEventHandler(instance, eventName, `${instance.key}-${eventName}`, replaceFormEventHandler);
  }

  // To emit the an intermediate event which will trigger the value emission to add the dynamically generated components
  dynamicComponentAddButtonCustomAction(instance: any, emitEventName: string) {
    emitAddDynamicComponent(instance, emitEventName);
  }

  // To emit the value which will remove the dynamically generated components
  dynamicComponentRemoveButtonCustomAction(instance: any) {
    emitRemoveDynamicComponent(instance);
  }

  // To emit the value which will add the dynamically generated components
  dynamicComponentFieldCustomDefaultValue(instance: any, eventName: string, emitEventName: string) {
    attachEventHandler(instance, eventName, emitEventName, dynamicComponentContainerAddDynamicComponentEventHandler);
  }

  // To add or remove dynamic components from formio logic
  dynamicComponentContainerCustomAction(instance: any, { value, toRemove }: { value: string; toRemove: boolean }) {
    addOrRemoveDynamicComponent(instance, value, toRemove);
  }

  // To add or remove dynamic components from formio event or trigger logic to  that handles it
  dynamicComponentContainerCustomDefaultValue(instance: any, eventName: string) {
    attachEventHandler(instance, eventName, `${instance.key}-${eventName}`, dynamicComponentContainerEventHandler);
    attachEventHandler(instance, 'beforeSetSubmission', '', dynamicComponentContainerBeforeSetSubmissionEventHandler);
    attachEventHandler(instance, 'languageChanged', eventName, dynamicComponentContainerLanguageChangedEventHandler);
  }

  // To render the summary of previous pages/screens
  wizardSummaryContainerCustomDefaultValue(instance: any, eventName: string) {
    attachEventHandler(instance, 'render', eventName, summaryRenderEventHandler);
  }
}
